import React, { useState, useEffect } from 'react'
import BasicPagination from '../Pagination';
import CommanInput from '../Constent/CommanInput';
import CommanDropDown from '../Constent/CommanDropDown';
import { Button } from 'react-bootstrap';
import { DELETS_WITH_TOKEN, GET_WITH_TOKEN, Post_WITH_TOKEN } from '../../Backend/Bakend';
import { showToast } from '../Constent/Toast';

const Package = () => {
    const [bookingData, setBookingData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [search, setSearch] = useState('');
    const [CurrentPage, setCurrentPage] = useState(1);
    const [IsEdit, setIsEdit] = useState(false);
    const [IsEditId, setIsEditId] = useState('');
    const [PostList, setPostList] = useState([]);
    const [CatId, setCatId] = useState('');
    const [subCatId, setSubCatId] = useState('');
    const [image, setImage] = useState('');
    const [Data, setData] = useState({
        title: "",
        category_id: "",
        sub_category_id: "",
        time: "",
        travel_medium: "",
        stay: "",
        price: "",
        status: "1",
        type: "",
        itinerary: "",
        post_slug:"",
    });
      const [selectedName, setSelectedName] = useState([]);
    const [vecotorImage, setVecotorImage] = useState([])
    const [selectedIds, setSelectedIds] = useState([]);
    const [postList1, setPostList1] = useState([])
    const [packageList, setPackageList] = useState([]);

    useEffect(() => {
        getCat();
        getSubCat();
        getPostShow12();
        getPostShow123();
    }, [])


    const Delete = (id) => {
        DELETS_WITH_TOKEN(`/api/packages/${id}`, '', success => {
            if (!!success) {
                showToast(success?.message || success?.msg || "booking categories delete ")
                GetPackage(CatId, subCatId)
            }
        }, error => {
            console.log(error);
        }, fail => {
            console.log(fail)
        })
    }

    const ShowById = (id) => {
        GET_WITH_TOKEN(`/api/packages/${id}`, "", success => {
            console.log('Success:=============>', success);
            if (!!success) {
                setData(success);
               let data =  success?.linked_vector_images?.map(res => res?.id);
               let data1 =  success?.linked_vector_images?.map(res => res?.key);

               setSelectedIds(data)
               setSelectedName(success?.linked_vector_images)
            }
        }, error => {
            console.log(error)
        }, fail => { console.log(fail) })
    };


    const ShowByIdCat = (id, subId) => {
        GET_WITH_TOKEN(`/api/booking-sub-categories/${id}`, "", success => {
            console.log('Success:', success);
            if (!!success) {
                setData(success)
            }
        }, error => {
            console.log(error)
        }, fail => { console.log(fail) })
    };

    const StatusData = [
        { id: 1, name: "Active", value: 1 }, { id: 0, name: "InActive", value: 0 }
    ]

    const getCat = () => {
        setPostList([])
        GET_WITH_TOKEN(`/api/booking-categories`, "", success => {
            console.log('Success:', success);
            if (!!success) {
                setPostList(success)
            }
        }, error => {
            console.log(error)
        }, fail => { console.log(fail) })
    }

    const getSubCat = () => {
        setPackageList([]);
        GET_WITH_TOKEN(`/api/booking-sub-categories`, "", success => {
            console.log('Success:', success);
            if (!!success) {
                setPackageList(success);
            }
        }, error => {
            console.log(error)
        }, fail => { console.log(fail) })
    }
    console.log(selectedIds,"selectedIds===========>")


    const handleSubmit = () => {
        const formData = new FormData();
        formData.append("title", Data.title);
        formData.append("category_id", Data.category_id);
        formData.append("sub_category_id", Data.sub_category_id);
        formData.append("time", Data.time);
        formData.append("travel_medium", Data.travel_medium);
        formData.append("stay", Data.stay);
        formData.append("price", Data.price);
        formData.append("status", Data.status);
        formData.append("post_slug",Data.post_slug);
        selectedIds?.map((res)=>{
            formData.append("vector_image_ids[]",res);
        })
        formData.append("type", Data.type);
        if (image) {
            formData.append("itinerary", image); // Attach the file
        }
        IsEdit && formData.append('_method', "PUT")
        Post_WITH_TOKEN(IsEdit ? `/api/packages/${IsEditId}` : '/api/packages', formData, success => {
            console.log(success);
            GetPackage(CatId, subCatId)
        }, error => {
            console.log(error)
        }, fail => {
            console.log(fail)
        })
    }

    const GetPackage = (id, subId) => {
        GET_WITH_TOKEN(`/api/packages/by-category?category_id=${id}&sub_category_id=${subId}`, "", success => {
            console.log('Success:', success);
            if (!!success) {
                setBookingData(success?.data);
            }
        }, error => {
            console.log(error)
        }, fail => { console.log(fail) })
    }

    const TypeList = [
        {
            value: 0,
            id: 1,
            name: "Affordable packages"
        },
        {
            value: 1,
            id: 2,
            name: "Luxury packages"
        }
    ]


     const getPostShow12 = async () => {
        GET_WITH_TOKEN("/api/getallpost", "",
          onSuccess => {
            console.log('Success:', onSuccess);
            if (!!onSuccess) {
              setPostList1(onSuccess.data);
              
            }
          },
          onFail => {
            console.log('Fail:', onFail);
            // showToast(onFail)
          },
          onError => {
            console.error('Error:', onError);
            // showToast("Somthing Wents to worng")
    
          })
      }

      const getPostShow123 = async () => {
        GET_WITH_TOKEN("/api/packages/vectorimages", "",
          onSuccess => {
            console.log('Success:', onSuccess);
            if (!!onSuccess) {
              setVecotorImage(onSuccess.data);
            }
          },
          onFail => {
            console.log('Fail:', onFail);
            // showToast(onFail)
          },
          onError => {
            console.error('Error:', onError);
            // showToast("Somthing Wents to worng")
    
          })
      }


      const handleSelectionChange = (e, name) => {
        console.log(name, "nasme");
        const selectedId = parseInt(e);
        console.log(e, "eeeee======>", selectedIds)
        if (!selectedIds.includes(selectedId)) {
          setSelectedIds([...selectedIds, selectedId]);
          // setSelectedName([...selectedName,selectedId])
        }
      };


      const handleRemove = (selectedList, removedItem) => {
        console.log(removedItem, "removedItem");
        const updatedIds = selectedIds.filter(id => id !== removedItem?.id);
        setSelectedIds(updatedIds);
      };

    return (
        <div>
            <div className='container mt-1'>
                <div className='row'>
                    <div className='col-lg-12  '>

                        <div className='card'>
                            <div className='card-header'>

                                <p style={{ fontSize: '25px' }}>Package</p>

                                <CommanDropDown displayValue="Select Categories" value={CatId} title={"Pls Select"} desTitle={"Select Categories"} Data={PostList} onChange={(e, name) => { console.log(e, name); setCatId(e) }} />
                                <CommanDropDown displayValue="Select Sub Categories" value={subCatId} title={"Pls Select"} desTitle={"Select Sub Categories"} Data={packageList} onChange={(e, name) => { console.log(e, name); setSubCatId(e); GetPackage(CatId, e) }} />

                                <div class="mb-3  mt-4 d-flex">
                                    <input type="text" class="form-control " id="exampleFormControlInput1"
                                        placeholder="Enter Name" style={{ width: '20%' }} onChange={(e) => { setSearch(e.target.value) }} />

                                    <button onClick={() => { }} type='btn' className='ms-4' style={{ backgroundColor: '#008c93', color: '#fff', border: 'none', padding: '7px 15px', borderRadius: '8px' }}>Search</button>

                                    <button type='btn' className='ms-2' style={{ backgroundColor: 'transparent', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Reset</button>


                                    <button type='btn' className='ms-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ backgroundColor: '#366fe9', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Add New</button>
                                </div>
                                <div>

                                </div>
                            </div>
                            <div className='card-body' style={{ height: 550, overflowY: "scroll" }}>
                                <p style={{ fontSize: '20px' }}>View All Package</p>

                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">id</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bookingData?.length > 0 ?
                                                bookingData?.map((res, index) => {
                                                    console.log(res, "res============>");
                                                    return (
                                                        <>
                                                            <tr>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{res?.title}</td>
                                                                <td>{res?.price}</td>
                                                                <td>
                                                                    <div >
                                                                        <button className='py-2 mx-2' style={{
                                                                            backgroundColor: '#3cc3b0', color: '#fff',
                                                                            border: 'none', borderRadius: '4px'
                                                                        }} onClick={() => { ShowById(res?.id); setIsEdit(true); setIsEditId(res?.id) }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Edit</button>
                                                                        <button onClick={() => {
                                                                            Delete(res?.id)
                                                                        }} className='py-2' style={{
                                                                            backgroundColor: '#9ba322', color: '#fff',
                                                                            border: 'none', borderRadius: '4px'
                                                                        }}>Delete</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }) : null
                                        }



                                    </tbody>

                                </table>
                                {
                                    bookingData?.length > 0 ? null :
                                        <div className="data-not-found">
                                            <h2 className="bounce">Data Not Found</h2>
                                            <p className="fade-in">Sorry, the requested data could not be found.</p>
                                        </div>
                                }
                            </div>
                            <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <BasicPagination count={totalPage} onChange={(e) => { }} />
                            </div>
                        </div>



                    </div>
                </div>
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: '50%' }}>
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add Package</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <div className='offcanvas-body'>
                    <CommanInput
                        value={Data?.title}
                        placeholder={"Title"}
                        type="text"
                        describtionShow={true}
                        desTitle={"Title"}
                        onChange={(e) => setData({ ...Data, title: e })}
                    />
                    <CommanDropDown
                        displayValue="Category"
                        value={Data?.category_id}
                        title={"Pls Select"}
                        desTitle={"Category"}
                        Data={PostList}
                        onChange={(e) => setData({ ...Data, category_id: e })}
                    />
                    <CommanDropDown
                        displayValue="Sub Category"
                        value={Data?.sub_category_id}
                        title={"Pls Select"}
                        desTitle={"Sub Category"}
                        Data={packageList}
                        onChange={(e) => setData({ ...Data, sub_category_id: e })}
                    />

                    <CommanDropDown
                        displayValue="Link Post"
                        isNew={true}
                        value={Data?.post_slug}
                        title={"Pls Select"}
                        desTitle={"Link Post"}
                        Data={postList1}
                        onChange={(e,v) => {console.log("e===============>",e,v);setData({...Data,post_slug:e})} }
                    />

                   {/* <CommanDropDown displayValue="post_slug" useMultiselect={true} title={"Pls Select"} desTitle={"Add Related Posts"} Data={PostList1} onChange={(e, name) => { console.log(e, name); handleSelectionChange(e, name) }} slectedData={selectedName || []} onRemove={handleRemove} /> */}

                     {/* <CommanDropDown
                        displayValue="post_slug"
                        useMultiselect={true}
                        title={"Pls Select"}
                        desTitle={"Link Post"}
                        Data={postList1}
                        slectedData={selectedName || []} 
                        onRemove={handleRemove}
                        onChange={(e, name) => { console.log(e, name); handleSelectionChange(e, name) }}
                    /> */}
                    <CommanInput
                        value={Data?.time}
                        placeholder={"Time"}
                        type="text"
                        describtionShow={true}
                        desTitle={"Time"}
                        onChange={(e) => setData({ ...Data, time: e })}
                    />
                    <CommanInput
                        value={Data?.travel_medium}
                        placeholder={"Travel Medium"}
                        type="text"
                        describtionShow={true}
                        desTitle={"Travel Medium"}
                        onChange={(e) => setData({ ...Data, travel_medium: e })}
                    />
                    <CommanInput
                        value={Data?.stay}
                        placeholder={"Stay"}
                        type="text"
                        describtionShow={true}
                        desTitle={"Stay"}
                        onChange={(e) => setData({ ...Data, stay: e })}
                    />
                    <CommanInput
                        value={Data?.price}
                        placeholder={"Price"}
                        type="number"
                        describtionShow={true}
                        desTitle={"Price"}
                        onChange={(e) => setData({ ...Data, price: e })}
                    />
                    <CommanDropDown
                        displayValue="Status"
                        value={Data?.status}
                        title={"Pls Select"}
                        desTitle={"Status"}
                        Data={StatusData}
                        onChange={(e) => setData({ ...Data, status: e })}
                    />
                    <CommanDropDown
                        displayValue="Type"
                        value={Data?.type}
                        title={"Pls Select"}
                        desTitle={"Type"}
                        Data={TypeList}
                        onChange={(e) => setData({ ...Data, type: e })}
                    />
                     {/* <CommanDropDown
                        displayValue="Vector Images"
                        value={Data?.type}
                        title={"Pls Select"}
                        desTitle={"Vector Images"}
                        Data={TypeList}
                        onChange={(e) => setData({ ...Data, type: e })}
                    /> */}

                       <CommanDropDown
                        displayValue="key"
                        useMultiselect={true}
                        title={"Pls Select"}
                        desTitle={"Vector Images"}
                        Data={vecotorImage}
                        slectedData={selectedName || []} 
                        onRemove={handleRemove}
                        onChange={(e, name) => { console.log(e, name); handleSelectionChange(e, name) }}
                    /> 

                    <CommanInput
                        type="file"
                        fileInput={true}
                        input={false}
                        textArea={false}
                        describtionShow={true}
                        desTitle={"Itinerary"}
                        placeholder={"Choose a file"}
                        onChange={(e) => {
                            setData({ ...Data, itinerary: URL.createObjectURL(e.target.files[0]) });
                            setImage(e.target.files[0])
                        }}
                    />
                    <div className='col-2'>
                        {
                            Data?.itinerary && (
                                /\.pdf$/i.test(Data.itinerary) ? (
                                    <a href={Data.itinerary} target="_blank" rel="noopener noreferrer" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img
                                            className="pdf-icon"
                                            src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                            alt="PDF File"
                                            style={{ width: 100, height: 100 }}
                                        />
                                    </a>
                                ) : (
                                    // Show image if it's not a PDF
                                    <img
                                        className="w-100"
                                        src={Data.itinerary}
                                        alt="#"
                                        style={{ justifyContent: "center", alignItems: "center", width: 100 }}
                                    />
                                )
                            )
                        }

                    </div>
                    <Button className='mt-3' onClick={() => { handleSubmit() }} data-bs-dismiss="offcanvas" aria-label="Close">Submit</Button>

                </div>
            </div>
        </div>
    )
}

export default Package;