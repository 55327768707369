import React, { useState, useEffect } from 'react'
import BasicPagination from '../Pagination';
import CommanInput from '../Constent/CommanInput';
import CommanDropDown from '../Constent/CommanDropDown';
import { Button } from 'react-bootstrap';
import { DELETS_WITH_TOKEN, GET_WITH_TOKEN, Post_WITH_TOKEN } from '../../Backend/Bakend';
import { showToast } from '../Constent/Toast';

const BookingCategories = () => {
    const [bookingData, setBookingData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [image, setImage] = useState('');
    const [search, setSearch] = useState('');
    const [CurrentPage, setCurrentPage] = useState(1);
    const [IsEdit, setIsEdit] = useState(false);
    const [IsEditId, setIsEditId] = useState('');
    const [categoriesData, setcategoriesData] = useState([])

    const [Data, setData] = useState({
        name: "",
        slug: "",
        status: "1",
        main_category_id: "",
        category_images: ""
    })

    useEffect(() => {
        getPostShow(1);
        GetCat()
    }, [])


    const GetCat = () => {
        GET_WITH_TOKEN("/api/base-categories", "", e => {
            // setBaseList(e.data);
            console.log(e, "eeeeee")
            // showToast("success")
            setcategoriesData(e.data)
        }, f => {
            showToast(f.message || f.msg)
        }, n => {
            showToast(n.message || n.msg || "Somthing Wants to rong")
        })
    }


    const ShowById = (id) => {
        GET_WITH_TOKEN(`/api/booking-categories/${id}`, "", success => {
            console.log('Success:', success);
            if (!!success) {
                setData(success?.data)
            }
        }, error => {
            console.log(error)
        }, fail => { console.log(fail) })
    };

    const getPostShow = (page) => {
        setBookingData([])
        GET_WITH_TOKEN(`/api/booking-categories/all?page=${page}&search=${search}`, "", success => {
            console.log('Success:', success);
            if (!!success) {
                setBookingData(success?.data?.data);
                setTotalPage(success?.data?.last_page);
                setCurrentPage(success?.data?.current_page);
            }
        }, error => {
            console.log(error)
        }, fail => { console.log(fail) })
    };

    const deletePost = (page) => {
        DELETS_WITH_TOKEN(`/api/booking-categories/${page}`, "", success => {
            console.log('Success:', success);
            if (!!success) {
                showToast(success?.message || success?.msg || "booking categories delete ")
                getPostShow(1);
            }
        }, error => {
            console.log(error)
        }, fail => { console.log(fail) })
    };


    const StatusData = [
        { id: 1, name: "Active", value: 1 }, { id: 0, name: "InActive", value: 0 }
    ]


    const onChange = (e, name) => {
        setData({ ...Data, [name]: e })
    }



    const handleSubmit = () => {

        const formdata = new FormData();
        formdata.append("name", Data?.name);
        formdata.append("slug", Data?.slug);
        formdata.append("active", Data?.status);
        image && formdata.append("category_images",image);
        formdata.append("main_category_id", Data?.main_category_id);
        IsEdit && formdata.append('_method', "PUT")
        Post_WITH_TOKEN(IsEdit ? `/api/booking-categories/${IsEditId}` : '/api/booking-categories', formdata, success => {
            console.log(success);
            getPostShow(1);
        }, error => {
            console.log(error)
        }, fail => {
            console.log(fail)
        })
    }

    return (
        <div>
            <div className='container mt-1'>
                <div className='row'>
                    <div className='col-lg-12  '>

                        <div className='card'>
                            <div className='card-header'>

                                <p style={{ fontSize: '25px' }}>Booking Categories</p>

                                <div class="mb-3  mt-4 d-flex">
                                    <input type="text" class="form-control " id="exampleFormControlInput1"
                                        placeholder="Enter Name" style={{ width: '20%' }} onChange={(e) => { setSearch(e.target.value) }} />

                                    <button onClick={() => { getPostShow(CurrentPage) }} type='btn' className='ms-4' style={{ backgroundColor: '#008c93', color: '#fff', border: 'none', padding: '7px 15px', borderRadius: '8px' }}>Search</button>

                                    <button type='btn' className='ms-2' style={{ backgroundColor: 'transparent', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Reset</button>


                                    <button type='btn' className='ms-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ backgroundColor: '#366fe9', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Add New</button>
                                </div>
                                <div>

                                </div>
                            </div>
                            <div className='card-body' style={{ height: 550, overflowY: "scroll" }}>
                                <p style={{ fontSize: '20px' }}>View All Booking Categories</p>

                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">id</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">slug</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            bookingData?.length > 0 ?
                                                bookingData?.map((res, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{res?.name}</td>
                                                                <td>{res?.slug}</td>
                                                                <td>{res?.status == 1 ? "Active" : "InActive"}</td>
                                                                <td>
                                                                    <div >
                                                                        <button className='py-2 mx-2' style={{
                                                                            backgroundColor: '#3cc3b0', color: '#fff',
                                                                            border: 'none', borderRadius: '4px'
                                                                        }} onClick={() => { ShowById(res?.id); setIsEdit(true); setIsEditId(res?.id) }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Edit</button>
                                                                        <button onClick={() => {
                                                                            deletePost(res?.id)
                                                                        }} className='py-2' style={{
                                                                            backgroundColor: '#9ba322', color: '#fff',
                                                                            border: 'none', borderRadius: '4px'
                                                                        }}>Delete</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }) : null
                                        }



                                    </tbody>

                                </table>
                                {
                                    bookingData?.length > 0 ? null :
                                        <div className="data-not-found">
                                            <h2 className="bounce">Data Not Found</h2>
                                            <p className="fade-in">Sorry, the requested data could not be found.</p>
                                        </div>
                                }
                            </div>
                            <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <BasicPagination count={totalPage} onChange={(e) => { getPostShow(e) }} />
                            </div>
                        </div>



                    </div>
                </div>
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: '50%' }}>
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Add Booking Categories</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <div className='offcanvas-body'>
                    <CommanInput value={Data?.name} placeholder={"Name"} type='text' describtionShow={true} desTitle={"Name"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, name: e }) }} />
                    <CommanInput value={Data?.slug} placeholder={"Slug"} type='text' describtionShow={true} desTitle={"Slug"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, slug: e }) }} />
                    <CommanDropDown value={Data?.main_category_id} title={"Pls Select"} desTitle={"Main Cateogry"} onChange={(e) => { onChange(e, "main_category_id") }} Data={categoriesData || []} />
                    <CommanInput placeholder={"Category image"} fileInput={true} input={false} textArea={false} describtionShow={true} desTitle={"Cateogry image"} onChange={(e) => {
                        setData({ ...Data, category_images: URL.createObjectURL(e.target.files[0]) });
                        setImage(e.target.files[0])
                    }} />
                    <div className='col-2'>
                        {
                            Data?.category_images && <img className='w-100' src={Data.category_images} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                        }
                    </div>
                    <CommanDropDown displayValue="Status" value={Data?.status} title={"Pls Select"} desTitle={"Status"} Data={StatusData} onChange={(e, name) => { console.log(e, name); setData({ ...Data, status: e }) }} />
                    <Button className='mt-3' onClick={() => { handleSubmit() }} data-bs-dismiss="offcanvas" aria-label="Close">Submit</Button>
                </div>
            </div>
        </div>
    )
}

export default BookingCategories