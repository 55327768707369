// import React, { useState, useEffect } from 'react';
// import hello from './img/images.jpg';
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import CommanInput from './Constent/CommanInput';
// import CommanDropDown from './Constent/CommanDropDown';
// import Imges from './Constent/Imges';
// import { Button } from 'react-bootstrap';
// import { GET_WITH_TOKEN, GET_WITH_TOKEN_PARAMS, Post_WITH_TOKEN } from '../Backend/Bakend';
// import { showToast } from './Constent/Toast';
// import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';


// const Home = () => {
//   const [editorState, seteditorState] = useState();
//   const [ImagesArray, setImagesArray] = useState([]);
//   const [BlogsData, setBlogsData] = useState([]);
//   const [questions, setQuestions] = useState([]);
//   const [Edit, setEdit] = useState(false)
//   const [PostList, setPostList] = useState([]);
//   const [catData, setcatData] = useState([]);
//   const [catData1, setcatData1] = useState([]);
//   const [CatList, setCatList] = useState([]);
//   const [categoriesData, setcategoriesData] = useState([]);
//   const [data1, setdata1] = useState("")
//   const [Data, setData] = useState({
//     upper_title: "",
//     upper_desc: "",
//     middle_title: "",
//     images1: [],
//     about_ptvi_title: "",
//     about_ptvi_desc: "",
//     category_ids: "",
//     footer_post_id_right: "",
//     footer_post_id_left: "",
//   })
//   //  console.log(Data,ImagesArray,"sAME")
//   const [ImagesArrayExit, setImagesArrayExit] = useState([])
//   const [Images, setImages] = useState([]);
//   const [Data1, setData1] = useState([]);
//   const [id, setid] = useState('')
//   console.log(Images, "Images");


//   const handleSubmit = () => {
//     if (Edit) {
//       CreateHome();
//     }
//     else {

//       CreateHome();

//       return
//       const validationErrors = validateForm(Data);

//       if (Object.keys(validationErrors).length === 0) {
//       } else {
//         showToast("All fields are required");
//       }
//     }

//   };

//   const validateForm = (formData) => {
//     const errors = {};
//     Object.keys(formData).forEach((fieldName) => {
//       if (fieldName === "images") {
//         if (!formData[fieldName] || formData[fieldName].length === 0) {
//           errors[fieldName] = "Base image is required";
//         }
//       } else if (!formData[fieldName].trim()) {
//         errors[fieldName] = `${fieldName} is required`;
//       }
//     });
//     return errors;
//   };




//   // const AddImages = (e) => {

//   //   console.log(e, "target");
//   //   let file = e.target.files[0];
//   //   const New = [...ImagesArray];
//   //   New?.push(file)
//   //   setImagesArray(New);
//   //   const Array = [...Data.images];
//   //   Array?.push({ image: URL.createObjectURL(file) });
//   //   setData({ ...Data, images: Array });
//   //   const Array1 = [...Images];
//   //   Array1?.push({ image: URL.createObjectURL(file) });
//   //   setImages(Array1);
//   // }

//   const AddImages = (e) => {
//     const fileList = e.target.files; // Get the FileList object
//     const updatedImagesArray1 = [...ImagesArray];
//     const updatedImagesArray2 = [...ImagesArray];
//     // const updatedDataImages = [...Data.images1];

//     // Iterate over each file in the FileList
//     for (let i = 0; i < fileList.length; i++) {
//       const file = fileList[i];

//       // Push each file to ImagesArray1
//       updatedImagesArray1.push(file);

//       // Create URL for preview
//       const imageUrl = URL.createObjectURL(file);

//       // Push image URL to Data images array
//       // updatedDataImages.push({ image: imageUrl });

//       // Push image URL to ImagesArray2
//       updatedImagesArray2.push({ image: imageUrl });
//     }

//     // Update state with the arrays containing all selected files and images
//     setImagesArray(updatedImagesArray1);
//     // setData({ ...Data, images1: updatedDataImages });
//     setImages(updatedImagesArray2);
//   };


//   const DeleteImage = (index) => {
//     const ArrayNew = [...ImagesArray]
//     ArrayNew.splice(index, 1)
//     setImagesArray(ArrayNew)
//     // const Array = [...Data.images1]
//     // Array.splice(index, 1)
//     // setData({ ...Data, images1: Array });
//     const Array1 = [...Images]
//     Array1.splice(index, 1)
//     setImages(Array1);
//   }

//   const DeleteImage1 = (index) => {
//     const ArrayNew = [...ImagesArrayExit]
//     ArrayNew.splice(index, 1)
//     setImagesArrayExit(ArrayNew)
//   }

//   const ShowById = (id) => {
//     setid(id);
//     GET_WITH_TOKEN_PARAMS("/api/home-settings", id,
//       onSuccess => {
//         console.log('Success=====home:', onSuccess);
//         if (!!onSuccess) {
//           showToast(onSuccess?.message || onSuccess?.msg || "Success");
//           setImagesArrayExit(onSuccess?.homeSetting?.images);
//           setQuestions(onSuccess?.homeSetting?.homequestion_keys);
//           const categoryIdArray = onSuccess?.homeSetting?.basic_category_ids.split(",").map(id => parseInt(id));
//           const categoryIdArray1 = onSuccess?.homeSetting?.blog_ids.split(",").map(id => parseInt(id));
//           const categoryIdArray2 = onSuccess?.homeSetting?.small_mirror_ids.split(",").map(id => parseInt(id));
//           const categoryIdArray3 = onSuccess?.homeSetting?.footer_category.split(",").map(id => parseInt(id));
//           setSelectedIds(categoryIdArray);
//           setSelectedIdsForBlog(categoryIdArray1);
//           setSelectedIdsForMirror(categoryIdArray2);
//           setSelectedNameIdsCategory(categoryIdArray3);
//           const filteredData = catData.filter(item => onSuccess?.homeSetting?.basic_category_ids.split(",").includes(item.id.toString()));
//           const filteredData1 = BlogsData.filter(item => onSuccess?.homeSetting?.blog_ids.split(",").includes(item.id.toString()));
//           const filteredData2 = catData.filter(item => onSuccess?.homeSetting?.small_mirror_ids.split(",").includes(item.id.toString()));
//           const filteredData3 = categoriesData.filter(item => onSuccess?.homeSetting?.footer_category.split(",").includes(item.id.toString()));
//           setSelectedNameForBlog(filteredData1);
//           setSelectedNameForMirror(filteredData2);
//           setSelectedName(filteredData);
//           setData(onSuccess?.homeSetting);
//           setSelectedNameCategory(filteredData3);
//           // setdata1(onSuccess?.homeSetting?.about_ptvi_desc);

//           console.log(onSuccess?.homeSetting?.about_ptvi_desc, "onSuccess?.homeSetting?.about_ptvi_desc=======================>")



//           const blocksFromHTML = convertFromHTML(onSuccess?.homeSetting?.about_ptvi_desc);
//           const contentState = ContentState.createFromBlockArray(blocksFromHTML);
//           let data = EditorState.createWithContent(contentState);

//           setdata1(data)
//         }
//       },
//       onFail => {
//         console.log('Fail:', onFail);
//         showToast(onFail)
//       },
//       onError => {
//         console.error('Error:', onError);
//         showToast("Somthing Wents to worng")

//       })
//   }


//   const getHome = async () => {
//     GET_WITH_TOKEN("/api/home-settings", "",
//       onSuccess => {
//         console.log('Success:', onSuccess);
//         if (!!onSuccess) {
//           setCatList(onSuccess);
//         }
//       },
//       onFail => {
//         console.log('Fail:', onFail);
//         showToast(onFail)
//       },
//       onError => {
//         console.error('Error:', onError);
//         showToast("Somthing Wents to worng")

//       })
//   }

//   const CreateHome = async () => {

//     const string = selectedIds.join(',');
//     const string1 = selectedIdsForBlog.join(",");
//     const string2 = selectedIdsForMirror.join(",");
//     const string3 = selectedNameIdsCategory?.join(",");
//     // console.log(string2, "=======>");
//     //  return


//     const contentState1 = data1?.getCurrentContent();
//     const rawContentState1 = convertToRaw(contentState1);
//     const htmlContent1 = draftToHtml(rawContentState1);


//     var formdata = new FormData();
//     formdata.append("upper_title", Data?.upper_title);
//     formdata.append("upper_desc", Data?.upper_desc);
//     formdata.append("basic_category_ids", string);
//     formdata.append("middle_title", Data?.middle_title);
//     formdata.append("blog_ids", string1);
//     formdata.append("about_ptvi_title", Data?.about_ptvi_title);
//     formdata.append("about_ptvi_desc", htmlContent1);
//     formdata.append("small_mirror_ids", string2);
//     formdata.append("category_ids", Data?.category_ids);
//     formdata.append("footer_post_id_right", Data?.footer_post_id_right);
//     formdata.append("footer_post_id_left", Data?.footer_post_id_left);
//     formdata.append("footer_category", string3);
//     {
//       ImagesArrayExit?.map((res) => {
//         console.log(res, "fdkfjjfjfjfjjf ======= qe;lmfmkerkm")
//         formdata.append("images_exist[]", res.img_path);
//       })
//     }
//     {
//       ImagesArray?.map((res) => {
//         formdata.append("images[]", res);
//       })
//     }
//     if (Edit) {
//       formdata.append('_method', "PUT");
//     }
//     questions?.map((res, index) => {
//       const content = res?.editorState?.getCurrentContent();
//       const rawContent = convertToRaw(content);
//       const htmlContent1 = draftToHtml(rawContent);
//       formdata.append(`question_keys[${index}][key_name]`, res?.name);
//       formdata.append(`question_keys[${index}][key_value]`, htmlContent1);
//     })
//     console.log(formdata, "formdata")
//     Post_WITH_TOKEN(Edit ? `/api/home-settings/${id}` : "/api/home-settings", formdata, e => {
//       console.log(e, "eeeeeeeee============>")
//       showToast(e?.message || e.msg || "success")
//     },
//       f => {
//         showToast(f?.message || f?.msg || "error")
//       },
//       n => {
//         showToast(n?.message || n?.msg || "Network Error")
//       })
//   }

//   const getCat = async () => {
//     GET_WITH_TOKEN("/api/base-categories", "",
//       onSuccess => {
//         console.log('Success:categories================>', onSuccess);
//         if (!!onSuccess) {
//           setcatData(onSuccess?.data);
//         }
//       },
//       onFail => {
//         console.log('Fail:', onFail);
//         showToast(onFail)
//       },
//       onError => {
//         console.error('Error:', onError);
//         showToast("Somthing Wents to worng")

//       })
//   }

//   const getCa1 = async () => {
//     GET_WITH_TOKEN("/api/categories", "",
//       onSuccess => {
//         console.log('Success:categories================>', onSuccess);
//         if (!!onSuccess) {
//           setcatData1(onSuccess?.data);
//         }
//       },
//       onFail => {
//         console.log('Fail:', onFail);
//         showToast(onFail)
//       },
//       onError => {
//         console.error('Error:', onError);
//         showToast("Somthing Wents to worng")

//       })
//   }

//   const getBlogs = async () => {
//     GET_WITH_TOKEN("/api/getblogs", "",
//       onSuccess => {
//         console.log('Success:========>', onSuccess);
//         if (!!onSuccess) {
//           setBlogsData(onSuccess?.blogs);
//         }
//       },
//       onFail => {
//         console.log('Fail:', onFail);
//         showToast(onFail)
//       },
//       onError => {
//         console.error('Error:', onError);
//         showToast("Somthing Wents to worng")

//       })
//   }

//   const getPostShow = async () => {
//     GET_WITH_TOKEN("/api/getallpost", "",
//       onSuccess => {
//         console.log('Success:', onSuccess);
//         if (!!onSuccess) {
//           setPostList(onSuccess?.data);
//         }
//       },
//       onFail => {
//         console.log('Fail:', onFail);
//         showToast(onFail)
//       },
//       onError => {
//         console.error('Error:', onError);
//         showToast("Somthing Wents to worng")

//       })
//   }


//   useEffect(() => {
//     getHome();
//     getPostShow();
//     getCat();
//     getCa1();
//     GetCatNew();
//     getBlogs();
//   }, []);

//   const onChange = (e, name) => {
//     setData({ ...Data, [name]: e })
//   }

//   const onChange1 = (e) => {
//     setdata1(e)
//   }

//   const [selectedIds, setSelectedIds] = useState([]);
//   const [selectedName, setSelectedName] = useState([]);
//   const [selectedIdsForBlog, setSelectedIdsForBlog] = useState([]);
//   const [selectedNameForBlog, setSelectedNameForBlog] = useState([]);
//   const [selectedIdsForMirror, setSelectedIdsForMirror] = useState([]);
//   const [selectedNameForMirror, setSelectedNameForMirror] = useState([]);
//   const [selectedNameCategory, setSelectedNameCategory] = useState([]);
//   const [selectedNameIdsCategory, setSelectedNameIdsCategory] = useState([]);


//   console.log(selectedNameCategory, "selectedNameCategory====>")
//   // const [Slected, setSlected] = useState(second)

//   const handleSelectionChange = (e, name) => {
//     console.log(name);
//     const selectedId = parseInt(e);
//     if (!selectedIds.includes(selectedId)) {
//       setSelectedIds([...selectedIds, selectedId]);
//       // setSelectedName([...selectedName, name])
//     }

//   };

//   const handleRemoveCat = (selectedList, removedItem) => {
//     console.log(removedItem, "removedItem");
//     const updatedIds = selectedIds.filter(id => id !== removedItem?.id);
//     setSelectedIds(updatedIds);
//     // Your logic for handling the removal of items here
//   };

//   const handleSelectionChangeBlog = (e, name) => {
//     console.log(name);
//     const selectedId = parseInt(e);
//     if (!selectedIds.includes(selectedId)) {
//       setSelectedIdsForBlog([...selectedIdsForBlog, selectedId]);
//       // setSelectedNameForBlog([...selectedNameForBlog, name])
//     }

//   };

//   const handleRemoveBlog = (selectedList, removedItem) => {
//     console.log(removedItem, "removedItem");
//     const updatedIds = selectedIdsForBlog.filter(id => id !== removedItem?.id);
//     setSelectedIdsForBlog(updatedIds);
//     // Your logic for handling the removal of items here
//   };


//   const handleSelectionChangeMirror = (e, name) => {
//     // console.log(name);
//     const selectedId = parseInt(e);
//     if (!selectedIds.includes(selectedId)) {
//       setSelectedIdsForMirror([...selectedIdsForMirror, selectedId]);
//       // setSelectedNameForMirror([...selectedNameForMirror, name])
//     }

//   };


//   const handleRemove = (selectedList, removedItem) => {
//     console.log(removedItem, "removedItem");
//     const updatedIds = selectedIdsForMirror.filter(id => id !== removedItem?.id);
//     setSelectedIdsForMirror(updatedIds);
//     // Your logic for handling the removal of items here
//   };

//   const handleSelection = (e, name, v) => {
//     const selectedId = parseInt(e);
//     if (!selectedNameIdsCategory.includes(selectedId)) {
//       setSelectedNameIdsCategory([...selectedNameIdsCategory, selectedId]);
//       setSelectedNameCategory([...selectedNameCategory, { id: v?.id, name: v?.name, slug: v?.slug }])
//     }
//   };

//   const handleRemove1 = (selectedList, removedItem) => {
//     console.log(removedItem, "removedItem");
//     const updatedIds = selectedNameIdsCategory.filter(id => id !== removedItem?.id);
//     setSelectedNameIdsCategory(updatedIds);
//     // Your logic for handling the removal of items here
//   };

//   const AddNewQuestions = () => {
//     const Array = [...questions];
//     Array.push({ name: "", value: "" });
//     setQuestions(Array);
//   }



//   const DeleteQuestion = (index) => {
//     const Array = [...questions];

//     console.log(Array.splice(index, 1), "hello");

//     setQuestions(Array)

//   }



//   const GetCatNew = () => {
//     GET_WITH_TOKEN("/api/categories", "", e => {
//       // setBaseList(e.data);
//       console.log(e, "eeeeee")
//       // showToast("success")
//       setcategoriesData(e.data)
//     }, f => {
//       showToast(f.message || f.msg)
//     }, n => {
//       showToast(n.message || n.msg || "Somthing Wants to rong")
//     })
//   }

//   return (
//     <div>
//       <div className='container mt-1'>
//         <div className='row'>
//           <div className='col-lg-12  '>

//             <div className='card'>
//               <div className='card-header'>
//                 <p style={{ fontSize: '25px' }}>Home Product Filter</p>



//                 <div class="mb-3  mt-4 d-flex">
//                   <input type="text" class="form-control " id="exampleFormControlInput1"
//                     placeholder="Enter Name" style={{ width: '20%' }} />

//                   <button type='btn' className='ms-4' style={{ backgroundColor: '#008c93', color: '#fff', border: 'none', padding: '7px 15px', borderRadius: '8px' }}>Search</button>

//                   <button type='btn' className='ms-2' style={{ backgroundColor: 'transparent', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Reset</button>
//                   <button type='btn' className='ms-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ backgroundColor: '#366fe9', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Add New</button>
//                 </div>
//                 <div>

//                 </div>
//               </div>
//               <div className='card-body' style={{ height: 550, overflowY: "scroll" }}>
//                 <p style={{ fontSize: '20px' }}>View All Products</p>

//                 <table class="table table-striped">
//                   <thead>
//                     <tr>
//                       <th scope="col">id</th>
//                       <th scope="col">image</th>
//                       <th scope="col">Name</th>
//                       <th scope="col">slug</th>
//                       <th scope="col">description</th>
//                       <th scope="col">post</th>
//                       <th scope="col">Parent</th>
//                       {/* <th scope="col">Country</th>
//       <th scope="col">Actions</th> */}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {
//                       CatList?.length > 0 ?
//                         CatList?.map((res) => {
//                           return (
//                             <>
//                               <tr>
//                                 <th scope="row">{res.id}</th>
//                                 <td><div><img src={hello} alt='img' style={{ width: '60px' }} /></div></td>
//                                 <td></td>
//                                 <td>{res.about_ptvi_title}</td>
//                                 <td></td>
//                                 <td></td>
//                                 <td></td>
//                                 <td></td>
//                                 <td>
//                                   <div >

//                                     <button className='py-2 mx-2' style={{
//                                       backgroundColor: '#3cc3b0', color: '#fff',
//                                       border: 'none', borderRadius: '4px'
//                                     }} onClick={() => { setEdit(true); ShowById(res?.id) }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >Edit</button>
//                                     <button className='py-2' style={{
//                                       backgroundColor: '#9ba322', color: '#fff',
//                                       border: 'none', borderRadius: '4px'
//                                     }}>Delete</button>
//                                   </div>
//                                 </td>
//                               </tr>
//                             </>
//                           )
//                         }) : null
//                     }



//                   </tbody>

//                 </table>
//                 {
//                   CatList?.length > 0 ? null :
//                     <div className="data-not-found">
//                       <h2 className="bounce">Data Not Found</h2>
//                       <p className="fade-in">Sorry, the requested data could not be found.</p>
//                     </div>
//                 }
//               </div>
//             </div>




//           </div>
//         </div>
//       </div>






//       <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: '85%' }}>
//         <div class="offcanvas-header">
//           <h5 id="offcanvasRightLabel">Add Home</h5>
//           <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
//         </div>

//         <div class="offcanvas-body">



//           <CommanInput multiple={true} placeholder={"Home images"} fileInput={true} input={false} textArea={false} describtionShow={true} desTitle={"Home images"} onChange={(e) => { AddImages(e) }} />


//           <div className='w-100 row'>
//             {
//               ImagesArrayExit?.map((res, index) => {
//                 return (
//                   <>
//                     <div className='col-2'>
//                       <img className='w-100' src={res.image || res.img_path} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
//                       <button className='w-100' onClick={() => { DeleteImage1(index) }}>
//                         Delete
//                       </button>
//                     </div>
//                   </>
//                 )
//               })
//             }
//           </div>

//           <div className='w-100 row'>
//             {
//               Images?.map((res, index) => {
//                 return (
//                   <>
//                     <div className='col-2'>
//                       <img className='w-100' src={res.image || res.img_path} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
//                       <button className='w-100' onClick={() => { DeleteImage(index) }}>
//                         Delete
//                       </button>
//                     </div>
//                   </>
//                 )
//               })
//             }
//           </div>

//           <CommanInput value={Data?.upper_title} title={"Enter Upper title"} placeholder={"Enter Upper title"} type='text' describtionShow={true} input={true} textArea={false} desTitle={"Enter Upper title"} onChange={(e) => { console.log(e, "data"); onChange(e, "upper_title") }} style={{}} />
//           <CommanInput value={Data?.upper_desc} title={"Enter Upper description"} placeholder={"Enter Upper description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Enter Upper description"} onChange={(e) => { console.log(e, "data"); onChange(e, "upper_desc") }} style={{ height: 160 }} />
//           <CommanDropDown displayValue="name" useMultiselect={true} title={"Pls Select Basic categories"} desTitle={"Basic categories"} Data={catData || []} onChange={(e, name) => { console.log(e, name); handleSelectionChange(e, name) }} slectedData={selectedName || []} onRemove={handleRemoveCat} />

//           <CommanInput value={Data?.middle_title} title={"Enter Middle section title"} placeholder={"Enter Middle section title"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Enter Middle section title"} onChange={(e) => { console.log(e, "data"); onChange(e, "middle_title") }} style={{ height: 160 }} />
//           <CommanDropDown displayValue='name' useMultiselect={true} title={"Pls Select Blogs"} desTitle={"Select Blogs"} Data={BlogsData || []} onChange={(e, name) => { handleSelectionChangeBlog(e, name) }} slectedData={selectedNameForBlog || []} onRemove={handleRemoveBlog} />

//           <CommanInput value={Data?.about_ptvi_title} title={"Enter About Ptvi Title"} placeholder={"Enter About Ptvi Title"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Enter About Ptvi Title"} onChange={(e) => { console.log(e, "data"); onChange(e, "about_ptvi_title") }} style={{ height: 160 }} />
//           {/* <CommanInput value={Data?.about_ptvi_desc} title={"Enter About Ptvi description"} placeholder={"Enter About Ptvi description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Enter About Ptvi description"} onChange={(e) => { console.log(e, "data"); onChange(e, "about_ptvi_desc") }} style={{ height: 160 }} /> */}

//           <div class="mb-2">
//             <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Enter About Ptvi description</label>
//             <div className='' style={{ border: "1px solid gray" }}>
//               <Editor
//                 editorState={data1}
//                 toolbarClassName="toolbarClassName"
//                 wrapperClassName="wrapperClassName"
//                 editorClassName="editorClassName"
//                 onEditorStateChange={(e) => { console.log(e, "data"); onChange1(e, "about_ptvi_desc") }}
//               /></div>
//           </div>



//           <CommanDropDown displayValue='name' useMultiselect={true} title={"Pls select Small Mirror Category"} desTitle={"Select Small Mirror Category"} Data={catData || []} onChange={(e, name) => { handleSelectionChangeMirror(e, name) }} slectedData={selectedNameForMirror || []} onRemove={handleRemove} />

//           <CommanDropDown value={Data?.category_ids} title={"Pls select Top category"} desTitle={"Select Top category"} Data={catData1 || []} onChange={(e) => { onChange(e, "category_ids") }} />
//           <CommanDropDown value={Data?.footer_post_id_right} title={"Pls select Footer post Right "} desTitle={"Select Footer post Right "} Data={PostList || []} onChange={(e) => { onChange(e, "footer_post_id_right") }} />
//           <CommanDropDown value={Data?.footer_post_id_left} title={"Pls select Footer post Left"} desTitle={"Select Footer post Left"} Data={PostList || []} onChange={(e) => { onChange(e, "footer_post_id_left") }} />
//           <CommanDropDown value={Data?.footer_post_id_left} title={"Pls select Footer category"} desTitle={"Select Footer category"} Data={catData1 || []} onChange={(e) => { onChange(e, "footer_categories") }} />
//           <CommanDropDown displayValue='name' useMultiselect={true} title={"Pls select Footer category"} desTitle={"Pls select Footer category"} Data={categoriesData || []} onChange={(e, name, v) => { console.log(e, name, v, "000000"); handleSelection(e, name, v) }} slectedData={selectedNameCategory || []} onRemove={handleRemove1} />


//           <div onClick={() => { AddNewQuestions() }} className='w-100 mt-2 mb-2 d-flex' style={{ justifyContent: "flex-start", alignItems: "flex-start", cursor: "pointer" }}>
//             <div className='d-flex m-2' style={{ justifyContent: "center", alignItems: "center" }}>
//               <img src={Imges?.add} alt='###' style={
//                 { height: 30 }
//               } />
//               <h6 className='' style={{ marginLeft: 20, color: "red" }}>Add Faq Asked Questions</h6>
//             </div>
//           </div>
//           <div className='d-flex w-100 row'>

//             {/* {
//               questions?.map((res, index) => {
//                 console.log(res, "jjjjjjjj")
//                 return (
//                   <>
//                     <div className='col-5 p-2'>
//                       <CommanInput textArea={true} input={false} placeholder={"Enter Questions"} type='text' describtionShow={true} desTitle={" Question"} onChange={(e) => {
//                         const newArray = [...questions];
//                         newArray[index].name = e;
//                         setQuestions(newArray);
//                       }}
//                         value={res.name}
//                       />
//                     </div>
//                     <div className='col-5 p-2'>
//                       <CommanInput textArea={true} input={false} placeholder={"Enter Answare"} type='text' describtionShow={true} desTitle={"Answare"} onChange={(e) => {
//                         const newArray = [...questions];
//                         newArray[index].value = e;
//                         setQuestions(newArray);
//                       }} value={res.value} />
//                     </div>
//                     <div onClick={() => { DeleteQuestion(index) }} className='col-2' style={{ marginTop: 35, alignItems: "center", justifyContent: "center" }}>
//                       <button>Delete</button>
//                     </div>
//                   </>
//                 )
//               })
//             } */}
//       {
//   questions?.map((res, index) => {
//     // Initialize editor state only if not already set
//     const initializeEditorState = (content) => {
//       const blocksFromHTML = convertFromHTML(content || "");
//       const contentState = ContentState.createFromBlockArray(blocksFromHTML);
//       const newArray = [...questions];
//       newArray[index].editorState = EditorState.createWithContent(contentState);
//       return EditorState.createWithContent(contentState);
//     };

//     // Use the existing editorState if available, otherwise initialize it
//     const editorState = res.editorState || initializeEditorState(res.value);

//     return (
//       <React.Fragment key={index}>
//         <div className='col-5 p-2'>
//           <CommanInput
//             textArea={true}
//             input={false}
//             placeholder={"Enter Questions"}
//             type='text'
//             describtionShow={true}
//             desTitle={"Question"}
//             onChange={(e) => {
//               const newArray = [...questions];
//               newArray[index].name = e.target.value;
//               setQuestions(newArray);
//             }}
//             value={res.name}
//           />
//         </div>

//         {/* Replace the Answer input field with the new text editor */}
//         <div className='col-5 p-2'>
//           <div className="mb-2">
//             <label
//               htmlFor={`editor_${index}`}
//               className="form-label"
//               style={{ fontWeight: '600' }}
//             >
//               Enter Answer
//             </label>
//             <div style={{ border: "1px solid gray" }}>
//               <Editor
//                 editorState={editorState}
//                 toolbarClassName="toolbarClassName"
//                 wrapperClassName="wrapperClassName"
//                 editorClassName="editorClassName"
//                 onEditorStateChange={(newEditorState) => {
//                   const newArray = [...questions];
//                   newArray[index].editorState = newEditorState; // Update the editorState
//                   newArray[index].value = newEditorState.getCurrentContent().getPlainText(); // Store plain text in `value`
//                   setQuestions(newArray); // Update the questions array
//                 }}
//               />
//             </div>
//           </div>
//         </div>

//         <div onClick={() => { DeleteQuestion(index) }} className='col-2' style={{ marginTop: 35, alignItems: "center", justifyContent: "center" }}>
//           <button>Delete</button>
//         </div>
//       </React.Fragment>
//     );
//   })
// }



//           </div>

//           <Button className="mt-3" onClick={() => { handleSubmit() }}>Submit</Button>

//         </div>
//       </div>
//     </div>
//   )
// }

// export default Home;


import React, { useState, useEffect } from 'react';
import hello from './img/images.jpg';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CommanInput from './Constent/CommanInput';
import CommanDropDown from './Constent/CommanDropDown';
import Imges from './Constent/Imges';
import { Button } from 'react-bootstrap';
import { GET_WITH_TOKEN, GET_WITH_TOKEN_PARAMS, Post_WITH_TOKEN } from '../Backend/Bakend';
import { showToast } from './Constent/Toast';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from "draft-js";
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { useRef } from 'react';


const Home = () => {
  const [editorState, seteditorState] = useState();
  const [ImagesArray, setImagesArray] = useState([]);
  const [BlogsData, setBlogsData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [Edit, setEdit] = useState(false)
  const [PostList, setPostList] = useState([]);
  const [catData, setcatData] = useState([]);
  const [catData1, setcatData1] = useState([]);
  const [CatList, setCatList] = useState([]);
  const [categoriesData, setcategoriesData] = useState([]);
  const [data1, setdata1] = useState("")
  const [Data, setData] = useState({
    upper_title: "",
    upper_desc: "",
    middle_title: "",
    images1: [],
    about_ptvi_title: "",
    about_ptvi_desc: "",
    category_ids: "",
    footer_post_id_right: "",
    footer_post_id_left: "",
  })
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedIdsForBlog, setSelectedIdsForBlog] = useState([]);
  const [selectedNameForBlog, setSelectedNameForBlog] = useState([]);
  const [selectedIdsForMirror, setSelectedIdsForMirror] = useState([]);
  const [selectedNameForMirror, setSelectedNameForMirror] = useState([]);
  const [selectedNameCategory, setSelectedNameCategory] = useState([]);
  const [selectedNameIdsCategory, setSelectedNameIdsCategory] = useState([]);
  //  console.log(Data,ImagesArray,"sAME")
  const [ImagesArrayExit, setImagesArrayExit] = useState([])
  const [Images, setImages] = useState([]);
  const [Data1, setData1] = useState([]);
  const [id, setid] = useState('')
  const [editors, setEditors] = useState([]);


  const handleSubmit = () => {
    if (Edit) {
      CreateHome();
    }
    else {

      CreateHome();

    }

  };





  const AddImages = (e) => {
    const fileList = e.target.files; // Get the FileList object
    const updatedImagesArray1 = [...ImagesArray];
    const updatedImagesArray2 = [...ImagesArray];
    // const updatedDataImages = [...Data.images1];

    // Iterate over each file in the FileList
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      // Push each file to ImagesArray1
      updatedImagesArray1.push(file);

      // Create URL for preview
      const imageUrl = URL.createObjectURL(file);

      // Push image URL to Data images array
      // updatedDataImages.push({ image: imageUrl });

      // Push image URL to ImagesArray2
      updatedImagesArray2.push({ image: imageUrl });
    }

    // Update state with the arrays containing all selected files and images
    setImagesArray(updatedImagesArray1);
    // setData({ ...Data, images1: updatedDataImages });
    setImages(updatedImagesArray2);
  };


  const DeleteImage = (index) => {
    const ArrayNew = [...ImagesArray]
    ArrayNew.splice(index, 1)
    setImagesArray(ArrayNew)
    // const Array = [...Data.images1]
    // Array.splice(index, 1)
    // setData({ ...Data, images1: Array });
    const Array1 = [...Images]
    Array1.splice(index, 1)
    setImages(Array1);
  }

  const DeleteImage1 = (index) => {
    const ArrayNew = [...ImagesArrayExit]
    ArrayNew.splice(index, 1)
    setImagesArrayExit(ArrayNew)
  }

  const ShowById = (id) => {
    setid(id);
    GET_WITH_TOKEN_PARAMS("/api/home-settings", id,
      onSuccess => {
        console.log('Success=====home:', onSuccess);
        if (!!onSuccess) {
          showToast(onSuccess?.message || onSuccess?.msg || "Success");
          setImagesArrayExit(onSuccess?.homeSetting?.images);

          
      
          setEditors(onSuccess?.homeSetting?.homequestion_keys?.map((data) => ({
            id: data.id,
            key: data.key,
            name: data.name,
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data.value).contentBlocks
              )
            ),
            contentState: data.value,
          })));
        
          const categoryIdArray = onSuccess?.homeSetting?.basic_category_ids.split(",").map(id => parseInt(id));
          const categoryIdArray1 = onSuccess?.homeSetting?.blog_ids.split(",").map(id => parseInt(id));
          const categoryIdArray2 = onSuccess?.homeSetting?.small_mirror_ids.split(",").map(id => parseInt(id));
          const categoryIdArray3 = onSuccess?.homeSetting?.footer_category.split(",").map(id => parseInt(id));
          setSelectedIds(categoryIdArray);
          setSelectedIdsForBlog(categoryIdArray1);
          setSelectedIdsForMirror(categoryIdArray2);
          setSelectedNameIdsCategory(categoryIdArray3);
          const filteredData = catData.filter(item => onSuccess?.homeSetting?.basic_category_ids.split(",").includes(item.id.toString()));
          const filteredData1 = BlogsData.filter(item => onSuccess?.homeSetting?.blog_ids.split(",").includes(item.id.toString()));
          const filteredData2 = catData.filter(item => onSuccess?.homeSetting?.small_mirror_ids.split(",").includes(item.id.toString()));
          const filteredData3 = categoriesData.filter(item => onSuccess?.homeSetting?.footer_category.split(",").includes(item.id.toString()));
          setSelectedNameForBlog(filteredData1);
          setSelectedNameForMirror(filteredData2);
          setSelectedName(filteredData);
          setData(onSuccess?.homeSetting);
          setSelectedNameCategory(filteredData3);
          // setdata1(onSuccess?.homeSetting?.about_ptvi_desc);

          console.log(onSuccess?.homeSetting?.about_ptvi_desc, "onSuccess?.homeSetting?.about_ptvi_desc=======================>")



          const blocksFromHTML = convertFromHTML(onSuccess?.homeSetting?.about_ptvi_desc);
          const contentState = ContentState.createFromBlockArray(blocksFromHTML);
          let data = EditorState.createWithContent(contentState);

          setdata1(data)
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }


  const getHome = async () => {
    GET_WITH_TOKEN("/api/home-settings", "",
      onSuccess => {
        console.log('Success:', onSuccess);
        if (!!onSuccess) {
          setCatList(onSuccess);
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }

  const CreateHome = async () => {

    const string = selectedIds.join(',');
    const string1 = selectedIdsForBlog.join(",");
    const string2 = selectedIdsForMirror.join(",");
    const string3 = selectedNameIdsCategory?.join(",");


    const contentState1 = data1?.getCurrentContent();
    const rawContentState1 = convertToRaw(contentState1);
    const htmlContent1 = draftToHtml(rawContentState1);


    var formdata = new FormData();
    formdata.append("upper_title", Data?.upper_title);
    formdata.append("upper_desc", Data?.upper_desc);
    formdata.append("basic_category_ids", string);
    formdata.append("middle_title", Data?.middle_title);
    formdata.append("blog_ids", string1);
    formdata.append("about_ptvi_title", Data?.about_ptvi_title);
    formdata.append("about_ptvi_desc", htmlContent1);
    formdata.append("small_mirror_ids", string2);
    formdata.append("category_ids", Data?.category_ids);
    formdata.append("footer_post_id_right", Data?.footer_post_id_right);
    formdata.append("footer_post_id_left", Data?.footer_post_id_left);
    formdata.append("footer_category", string3);
    {
      ImagesArrayExit?.map((res) => {
        formdata.append("images_exist[]", res.img_path);
      })
    }
    {
      ImagesArray?.map((res) => {
        formdata.append("images[]", res);
      })
    }
    if (Edit) {
      formdata.append('_method', "PUT");
    }
    editors.forEach((res, index) => {
      formdata.append(`question_keys[${index}][key_name]`, res.name);
      formdata.append(`question_keys[${index}][key_value]`, res?.contentState);
    });
    Post_WITH_TOKEN(Edit ? `/api/home-settings/${id}` : "/api/home-settings", formdata, e => {
      console.log(e, "eeeeeeeee============>")
      showToast(e?.message || e.msg || "success")
    },
      f => {
        showToast(f?.message || f?.msg || "error")
      },
      n => {
        showToast(n?.message || n?.msg || "Network Error")
      })
  }

  const getCat = async () => {
    GET_WITH_TOKEN("/api/base-categories", "",
      onSuccess => {
        console.log('Success:categories================>', onSuccess);
        if (!!onSuccess) {
          setcatData(onSuccess?.data);
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }

  const getCa1 = async () => {
    GET_WITH_TOKEN("/api/categories", "",
      onSuccess => {
        console.log('Success:categories================>', onSuccess);
        if (!!onSuccess) {
          setcatData1(onSuccess?.data);
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }

  const getBlogs = async () => {
    GET_WITH_TOKEN("/api/getblogs", "",
      onSuccess => {
        console.log('Success:========>', onSuccess);
        if (!!onSuccess) {
          setBlogsData(onSuccess?.blogs);
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }

  const getPostShow = async () => {
    GET_WITH_TOKEN("/api/getallpost", "",
      onSuccess => {
        console.log('Success:', onSuccess);
        if (!!onSuccess) {
          setPostList(onSuccess?.data);
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }


  useEffect(() => {
    getHome();
    getPostShow();
    getCat();
    getCa1();
    GetCatNew();
    getBlogs();
  }, []);

  const onChange = (e, name) => {
    setData({ ...Data, [name]: e })
  }

  const onChange1 = (e) => {
    setdata1(e)
  }




  console.log(selectedNameCategory, "selectedNameCategory====>")
  // const [Slected, setSlected] = useState(second)

  const handleSelectionChange = (e, name) => {
    console.log(name);
    const selectedId = parseInt(e);
    if (!selectedIds.includes(selectedId)) {
      setSelectedIds([...selectedIds, selectedId]);
      // setSelectedName([...selectedName, name])
    }

  };

  const handleRemoveCat = (selectedList, removedItem) => {
    console.log(removedItem, "removedItem");
    const updatedIds = selectedIds.filter(id => id !== removedItem?.id);
    setSelectedIds(updatedIds);
    // Your logic for handling the removal of items here
  };

  const handleSelectionChangeBlog = (e, name) => {
    console.log(name);
    const selectedId = parseInt(e);
    if (!selectedIds.includes(selectedId)) {
      setSelectedIdsForBlog([...selectedIdsForBlog, selectedId]);
      // setSelectedNameForBlog([...selectedNameForBlog, name])
    }

  };

  const handleRemoveBlog = (selectedList, removedItem) => {
    console.log(removedItem, "removedItem");
    const updatedIds = selectedIdsForBlog.filter(id => id !== removedItem?.id);
    setSelectedIdsForBlog(updatedIds);
    // Your logic for handling the removal of items here
  };


  const handleSelectionChangeMirror = (e, name) => {
    // console.log(name);
    const selectedId = parseInt(e);
    if (!selectedIds.includes(selectedId)) {
      setSelectedIdsForMirror([...selectedIdsForMirror, selectedId]);
      // setSelectedNameForMirror([...selectedNameForMirror, name])
    }

  };


  const handleRemove = (selectedList, removedItem) => {
    console.log(removedItem, "removedItem");
    const updatedIds = selectedIdsForMirror.filter(id => id !== removedItem?.id);
    setSelectedIdsForMirror(updatedIds);
    // Your logic for handling the removal of items here
  };

  const handleSelection = (e, name, v) => {
    const selectedId = parseInt(e);
    if (!selectedNameIdsCategory.includes(selectedId)) {
      setSelectedNameIdsCategory([...selectedNameIdsCategory, selectedId]);
      setSelectedNameCategory([...selectedNameCategory, { id: v?.id, name: v?.name, slug: v?.slug }])
    }
  };

  const handleRemove1 = (selectedList, removedItem) => {
    console.log(removedItem, "removedItem");
    const updatedIds = selectedNameIdsCategory.filter(id => id !== removedItem?.id);
    setSelectedNameIdsCategory(updatedIds);
    // Your logic for handling the removal of items here
  };

  const AddNewQuestions = () => {
    setQuestions([ ...questions,
      { editorState: EditorState.createEmpty(), contentState: "",name:"" },]);
  };



  const DeleteQuestion = (index) => {
    const Array = [...editors];

    console.log(Array.splice(index, 1), "hello");

    setEditors(Array)

  }



  const GetCatNew = () => {
    GET_WITH_TOKEN("/api/categories", "", e => {
      console.log(e, "eeeeee")
      setcategoriesData(e.data)
    }, f => {
      showToast(f.message || f.msg)
    }, n => {
      showToast(n.message || n.msg || "Somthing Wants to rong")
    })
  }



  const handleEditorStateChange = (editorState, index) => {
    const updatedEditors = editors.map((editor, i) => 
      i === index ? { ...editor, editorState } : editor
    );
    setEditors(updatedEditors);
  };

  const handleContentStateChange = (contentState, index) => {
    const htmlContent = draftToHtml(contentState);
    const updatedEditors = editors.map((editor, i) => 
      i === index ? { ...editor, contentState: htmlContent } : editor
    );
    setEditors(updatedEditors);
  };
   const addEditor = () => {
    setEditors((prevEditors) => [
      ...prevEditors, 
      { editorState: EditorState.createEmpty(), contentState: "", name: "" }
    ]);
  };


  const handleNameChange = (e, index) => {
    const newName = e;
    setEditors((prevEditors) =>
      prevEditors.map((editor, i) =>
        i === index ? { ...editor, name: newName } : editor
      )
    );
  };


  return (
    <div>
      <div className='container mt-1'>
        <div className='row'>
          <div className='col-lg-12  '>

            <div className='card'>
              <div className='card-header'>
                <p style={{ fontSize: '25px' }}>Home Product Filter</p>



                <div class="mb-3  mt-4 d-flex">
                  <input type="text" class="form-control " id="exampleFormControlInput1"
                    placeholder="Enter Name" style={{ width: '20%' }} />

                  <button type='btn' className='ms-4' style={{ backgroundColor: '#008c93', color: '#fff', border: 'none', padding: '7px 15px', borderRadius: '8px' }}>Search</button>

                  <button type='btn' className='ms-2' style={{ backgroundColor: 'transparent', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Reset</button>
                  <button type='btn' className='ms-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ backgroundColor: '#366fe9', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Add New</button>
                </div>
                <div>

                </div>
              </div>
              <div className='card-body' style={{ height: 550, overflowY: "scroll" }}>
                <p style={{ fontSize: '20px' }}>View All Products</p>

                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">id</th>
                      <th scope="col">image</th>
                      <th scope="col">Name</th>
                      <th scope="col">slug</th>
                      <th scope="col">description</th>
                      <th scope="col">post</th>
                      <th scope="col">Parent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      CatList?.length > 0 ?
                        CatList?.map((res) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">{res.id}</th>
                                <td><div><img src={hello} alt='img' style={{ width: '60px' }} /></div></td>
                                <td></td>
                                <td>{res.about_ptvi_title}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <div >

                                    <button className='py-2 mx-2' style={{
                                      backgroundColor: '#3cc3b0', color: '#fff',
                                      border: 'none', borderRadius: '4px'
                                    }} onClick={() => { setEdit(true); ShowById(res?.id) }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >Edit</button>
                                    <button className='py-2' style={{
                                      backgroundColor: '#9ba322', color: '#fff',
                                      border: 'none', borderRadius: '4px'
                                    }}>Delete</button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        }) : null
                    }



                  </tbody>

                </table>
                {
                  CatList?.length > 0 ? null :
                    <div className="data-not-found">
                      <h2 className="bounce">Data Not Found</h2>
                      <p className="fade-in">Sorry, the requested data could not be found.</p>
                    </div>
                }
              </div>
            </div>




          </div>
        </div>
      </div>






      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: '85%' }}>
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Add Home</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">



          <CommanInput multiple={true} placeholder={"Home images"} fileInput={true} input={false} textArea={false} describtionShow={true} desTitle={"Home images"} onChange={(e) => { AddImages(e) }} />


          <div className='w-100 row'>
            {
              ImagesArrayExit?.map((res, index) => {
                return (
                  <>
                    <div className='col-2'>
                      <img className='w-100' src={res.image || res.img_path} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                      <button className='w-100' onClick={() => { DeleteImage1(index) }}>
                        Delete
                      </button>
                    </div>
                  </>
                )
              })
            }
          </div>

          <div className='w-100 row'>
            {
              Images?.map((res, index) => {
                return (
                  <>
                    <div className='col-2'>
                      <img className='w-100' src={res.image || res.img_path} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                      <button className='w-100' onClick={() => { DeleteImage(index) }}>
                        Delete
                      </button>
                    </div>
                  </>
                )
              })
            }
          </div>

          <CommanInput value={Data?.upper_title} title={"Enter Upper title"} placeholder={"Enter Upper title"} type='text' describtionShow={true} input={true} textArea={false} desTitle={"Enter Upper title"} onChange={(e) => { console.log(e, "data"); onChange(e, "upper_title") }} style={{}} />
          <CommanInput value={Data?.upper_desc} title={"Enter Upper description"} placeholder={"Enter Upper description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Enter Upper description"} onChange={(e) => { console.log(e, "data"); onChange(e, "upper_desc") }} style={{ height: 160 }} />
          <CommanDropDown displayValue="name" useMultiselect={true} title={"Pls Select Basic categories"} desTitle={"Basic categories"} Data={catData || []} onChange={(e, name) => { console.log(e, name); handleSelectionChange(e, name) }} slectedData={selectedName || []} onRemove={handleRemoveCat} />

          <CommanInput value={Data?.middle_title} title={"Enter Middle section title"} placeholder={"Enter Middle section title"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Enter Middle section title"} onChange={(e) => { console.log(e, "data"); onChange(e, "middle_title") }} style={{ height: 160 }} />
          <CommanDropDown displayValue='name' useMultiselect={true} title={"Pls Select Blogs"} desTitle={"Select Blogs"} Data={BlogsData || []} onChange={(e, name) => { handleSelectionChangeBlog(e, name) }} slectedData={selectedNameForBlog || []} onRemove={handleRemoveBlog} />

          <CommanInput value={Data?.about_ptvi_title} title={"Enter About Ptvi Title"} placeholder={"Enter About Ptvi Title"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Enter About Ptvi Title"} onChange={(e) => { console.log(e, "data"); onChange(e, "about_ptvi_title") }} style={{ height: 160 }} />
          {/* <CommanInput value={Data?.about_ptvi_desc} title={"Enter About Ptvi description"} placeholder={"Enter About Ptvi description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={"Enter About Ptvi description"} onChange={(e) => { console.log(e, "data"); onChange(e, "about_ptvi_desc") }} style={{ height: 160 }} /> */}

          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Enter About Ptvi description</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={data1}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); onChange1(e, "about_ptvi_desc") }}
              /></div>
          </div>



          <CommanDropDown displayValue='name' useMultiselect={true} title={"Pls select Small Mirror Category"} desTitle={"Select Small Mirror Category"} Data={catData || []} onChange={(e, name) => { handleSelectionChangeMirror(e, name) }} slectedData={selectedNameForMirror || []} onRemove={handleRemove} />

          <CommanDropDown value={Data?.category_ids} title={"Pls select Top category"} desTitle={"Select Top category"} Data={catData1 || []} onChange={(e) => { onChange(e, "category_ids") }} />
          <CommanDropDown value={Data?.footer_post_id_right} title={"Pls select Footer post Right "} desTitle={"Select Footer post Right "} Data={PostList || []} onChange={(e) => { onChange(e, "footer_post_id_right") }} />
          <CommanDropDown value={Data?.footer_post_id_left} title={"Pls select Footer post Left"} desTitle={"Select Footer post Left"} Data={PostList || []} onChange={(e) => { onChange(e, "footer_post_id_left") }} />
          {/* <CommanDropDown value={Data?.footer_post_id_left} title={"Pls select Footer category"} desTitle={"Select Footer category"} Data={catData1 || []} onChange={(e) => { onChange(e, "footer_categories") }} /> */}
          <CommanDropDown displayValue='name' useMultiselect={true} title={"Pls select Footer category"} desTitle={"Pls select Footer category"} Data={categoriesData || []} onChange={(e, name, v) => { console.log(e, name, v, "000000"); handleSelection(e, name, v) }} slectedData={selectedNameCategory || []} onRemove={handleRemove1} />


          <div onClick={() => { addEditor() }} className='w-100 mt-2 mb-2 d-flex' style={{ justifyContent: "flex-start", alignItems: "flex-start", cursor: "pointer" }}>
            <div className='d-flex m-2' style={{ justifyContent: "center", alignItems: "center" }}>
              <img src={Imges?.add} alt='###' style={
                { height: 30 }
              } />
              <h6 className='' style={{ marginLeft: 20, color: "red" }}>Add Faq Asked Questions</h6>
            </div>
          </div>
          <div className='d-flex w-100 row'>

            {
              editors?.map((editor, index) => {
                return (
                  <>
                    <div className='col-5 p-2'>
                      <CommanInput
                        textArea={true}
                        input={false}
                        placeholder={"Enter Questions"}
                        type='text'
                        describtionShow={true}
                        desTitle={"Question"}
                        onChange={(e) => {
                          handleNameChange(e,index);
                        }}
                        value={editor?.name}
                      />
                    </div>
                    <div className='col-5 mt-2' style={{border:"1px solid #000"}}>
                    <Editor
                    editorState={editor.editorState}
                    toolbarClassName="editor-toolbar"
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    onEditorStateChange={(state) => handleEditorStateChange(state, index)}
                    onContentStateChange={(content) => handleContentStateChange(content, index)}
                    
                  />
                    </div>
                    <div onClick={() => { DeleteQuestion(index) }} className='col-2' style={{ marginTop: 35, alignItems: "center", justifyContent: "center" }}>
                      <button>Delete</button>
                    </div>
                  </>
                );
              })
            }

          </div>

          <Button data-bs-dismiss="offcanvas" aria-label="Close" className=" mt-3" onClick={() => { handleSubmit() }}>Submit</Button>

        </div>
      </div>
    </div>
  )
}

export default Home;