
const Base_Url = "https://api.placestovisitindia.com";




export const  Post_Api  = async(
    url,body,onSuccess,onFail,onError
)=>{
    // const csrfToken = getCsrfToken();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6Ikx0aDlxVGFuN2FBdDBSekduODUxVGc9PSIsInZhbHVlIjoiQXB3ZEowcWdxVThVb29sbTZhSHhtdFpJQjBtRjdpRHNERE1QdDE4UzJnbmpyclRpNy9mTXJzMU8zOUFOd2pDeS9PZEFia3NmQ2Z6a3BheTNoaG5UVUt6Rlk5ZDFVSHR3WEY1VklhNks0VFE0b2ZKTC9wT2lzUlgxY2F1ZzZtOHoiLCJtYWMiOiJkZTRmMTgxOTBlYjk5NzI3M2EyOTE5YzUwMjk3Y2VhMTI0NzkyYzc2Njc2MThhMjczYzEyMWQ4ODA3ODUyYjhiIiwidGFnIjoiIn0%3D; laravel_session=eyJpdiI6IjIrMG9pOUhxdldSRzgydTlRNk5sVUE9PSIsInZhbHVlIjoiZ0MzTWYycFNPVmJtNEhNdFJYbFZkQ2U4Y2NSQ1ZJR2lEQ3kzc3cxWU5ZbHJhU3V5bVNIZVFYMUJ2Ni8ycmRFWVppVnEvMDBTSnJiRVBSRDlhdmJ5U1pLRFVRcXFkS2ZMRXQ2NTJvM2ZaeDlkRGlxbUU3YkdYMUppQXA3UXd2NHEiLCJtYWMiOiI5ODQ0NGNmM2UxOTZiZmMwNTMwN2RlOGJhYWIxYTI5YzljYjU2ZDJhNjM0ZDlhNTU1ZGE3MDczMTY4YmVmMjdlIiwidGFnIjoiIn0%3D");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: body,
      redirect: 'follow'
    };

try {
    fetch(`${Base_Url}${url}`, requestOptions)
    .then(
       async(response)=>{
        const data = await response.json();
        console.log(data,"ffffff")
        onSuccess(data)
       }
    ).catch(
        error=>{
            
            console.log(error)
        }
    )
} catch (error) {
    onError("Network error!")
}
}

export const  Post_WITH_TOKEN  = async(
    url,body,onSuccess,onFail,onError
)=>{
    
    // const csrfToken = getCsrfToken();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("Token")}`);
   var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: body,
      redirect: 'follow'
    };

try {
    fetch(`${Base_Url}${url}`, requestOptions)
    .then(
       async(response)=>{
        const data = await response.json();
        console.log(data,"ffffff")
        onSuccess(data)
       }
    ).catch(
        error=>{
            onFail(error)
            console.log(error)
        }
    )
} catch (error) {
    onError("Network error")
}
}

export const  GET_WITH_TOKEN  = async(
    url,body,onSuccess,onFail,onError
)=>{
    // const csrfToken = getCsrfToken();
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("Token")}`);
   var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };
  
try {
    fetch(`${Base_Url}${url}`, requestOptions)
    .then(
       async(response)=>{
        const data = await response.json();
        console.log(data,"ffffff")
        onSuccess(data)
       }
    ).catch(
        error=>{
            console.log(error)
            onFail(error)
        }
    )
} catch (error) {
    onError("Network error")
}
}

export const  DELETS_WITH_TOKEN  = async(
    url,body,onSuccess,onFail,onError
)=>{
    // const csrfToken = getCsrfToken();
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("Token")}`);
   var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
    };
  
try {
    fetch(`${Base_Url}${url}`, requestOptions)
    .then(
       async(response)=>{
        const data = await response.json();
        console.log(data,"ffffff")
        onSuccess(data)
       }
    ).catch(
        error=>{
            console.log(error)
            onFail(error)
        }
    )
} catch (error) {
    onError("Network error")
}
}

export const GET_WITH_TOKEN_PARAMS =  async(
    url,body,onSuccess,onFail,onError
)=>{
    // const csrfToken = getCsrfToken();
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("Token")}`);
   var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };
  
try {
    fetch(`${Base_Url}${url}/${body}`, requestOptions)
    .then(
       async(response)=>{
        const data = await response.json();
        console.log(data,"ffffff")
        onSuccess(data)
       }
    ).catch(
        error=>{
            console.log(error)
            onFail(error)
        }
    )
} catch (error) {
    onError("Network error")
}
}